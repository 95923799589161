.gempik_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 185px;
  padding: 20px;
  border-top: 4px solid #f0f0f0;
  background-color: #001d35;
}

.gempik_footer_contents {
  width: 70%;
  height: 100%;
  padding: 20px;
  color: #ffffff;
}

.gempik_footer_contents h3 {
}

.gempik_footer_contents p {
  margin: 0;
  padding-bottom: 4px;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
}

.gempik_footer_contents span {
  font-size: 16px;
  font-weight: 700;
  color: #9b9b9b;
}

.gempik_footer_policy {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 30%;
  height: 100%;
  padding: 20px;
}

.gempik_footer_policy_link {
  padding: 20px;
  font-size: 18px;
  font-weight: 800;
  text-decoration: none;
  color: #9b9b9b;
}
