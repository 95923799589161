.Home {
  width: 100%;
  height: 100%;
  background-color: #001d35;
}

.gempik_header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  margin: 0 auto;
  padding: 20px;
  background: #ffffff;
}

.gempik_header_contents {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 80vh;
  /* margin: 0 auto; */
  padding: 20px;
  background: #ffffff;
}

.gempik_logo {
  margin-bottom: 40px;
}

.gempik_header_title h1 {
  margin: 0;
  font-size: 50px;
  font-weight: 700;
  color: #001d35;
}

.gempik_header_title h2 {
  margin: 0;
  font-size: 30px;
  font-weight: 700;
  color: #001d35;
}

.gempik_phone {
  margin-left: 40px;
  width: 300px;
}

.gempik_app_link {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.gempik_app_link_apple {
  margin-right: 30px;
}

.gempik_app_link_google {
}

.gempik_app_link img {
  width: 300px;
  height: 80px;
}

.scroll_img_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px;
  background: #ffffff;
}

.gempik_background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 60px 0px;
}

.gempik_background img {
  width: 820px;
  height: 300px;
  border-radius: 20px;
}

.gempik_guide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 40px 0px 10px;
  font-family: "Madimi One", sans-serif;
  font-size: 46px;
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
}

.gempik_main {
  width: 900px;
  margin: 40px auto;
  border-radius: 20px;
  inline-size: 900px;
  border: 30px solid #f0f0f0;
  /* outline: 25px solid #f0f0f0; */
}

.slick-slide div {
  background: #f0f0f0;
}

.gempik_image {
  width: 280px;
  height: 588px;
  margin : 0 10px;
  border-radius: 10px;
}
