.gempik_nav {
  width: 100vw;
  height: 100px;
  background-color: #001d35;
}

.gempik_nav img {
  padding: 20px;
  width: 200px;
}
